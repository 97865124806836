.skew {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 120px;
}
.image {
	margin-right: 60px;
}
.image img {
	width: 100%;
	object-fit: cover;
}
.image,
.description {
	width: 100%;
}
.skew .description p {
	text-align: justify;
}
.skew .description h2 {
	line-height: 1.2;
}

.description .btn {
	background: var(--a-blue);
	font-family: var(--ffamily-workSans);
	padding: 12px 16px;
	border-radius: 4px;
}
.description p {
	margin: 30px 0px;
}
@media screen and (max-width: 1400px) {
	.skew {
		background-position: -200%;
		padding: 20px 160px;
	}
}
@media screen and (max-width: 1200px) {
	.skew {
		background-position: -200%;
		padding: 20px 100px;
	}
	.title h3 {
		font-size: 32px;
	}
	.description p {
		font-size: 18px;
	}
}
@media screen and (max-width: 992px) {
	.skew {
		padding: 20px 80px;
	}
}
@media screen and (max-width: 768px) {
	.skew {
		height: 80vh;
		justify-content: center;
		padding: 50px 60px;
		flex-direction: column;
		align-items: flex-start;
	}
	.image {
		margin-bottom: 50px;
	}
	.image img {
		width: 80%;
		object-fit: cover;
	}
	.description {
		width: 80%;
	}
	.description h2 {
		font-size: 32px;
	}
	.description p {
		margin: 10px 0px 20px 0px;
	}
}
@media screen and (max-width: 576px) {
	.skew {
		padding: 50px 30px;
	}
	.image img {
		width: 100%;
		object-fit: cover;
	}
	.description {
		width: 100%;
	}
}
