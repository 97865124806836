.blog-card {
  display: flex;
  width: 80%;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(92, 92, 92, 0.246);
  cursor: pointer;
}
.blog-card .blog-info {
  width: 70%;
}
.blog-card .blog-info .posted {
  color: #828282;
  margin-top: 10px;
}
.blog-card .blog-info .tags {
  color: #828282;
  margin-top: 10px;
}
.blog-card .blog-info h2 {
  font-family: var(--ffamily-workSans);
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 12px;
}
.blog-card .blog-info p {
  font-size: 20px;
  opacity: 0.8;
}
.blog-card .blog-info .link {
  color: var(--c-dark);
  font-weight: 700;
}
.blog-card .blog-info .link:hover {
  color: var(--a-blue);
}
.blog-card .blog-img {
  filter: grayscale(1);
  clip-path: circle(30%);
}
.blog-card:hover .blog-img {
  filter: grayscale(0);
}
.blog-card .blog-img img {
  width: 300px;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .blog-card {
    width: 100%;
  }
  .blog-card .blog-info h2 {
    font-size: 24px;
  }
  .blog-card .blog-info p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .blog-card .blog-img img {
    width: 250px;
    object-fit: cover;
  }
}
@media screen and (max-width: 576px) {
  .blog-card {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20px 0px;
  }
  .blog-card .blog-info {
    width: 100%;
    margin-bottom: 20px;
  }
  .blog-card .blog-info h2 {
    font-size: 20px;
  }
  .blog-card .blog-info p {
    font-size: 16px;
  }
  .blog-card .blog-img {
    filter: grayscale(1);
    clip-path: circle(30%);
  }
  .blog-card .blog-img img {
    width: 150px;
    object-fit: cover;
  }
}
