nav {
	height: 10vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 120px;
	background: var(--a-blue800);
	font-family: var(--ffamily-workSans);
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
}
a {
	color: var(--c-light);
	text-decoration: none;
}

nav .logo {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}
.menu {
	display: none;
}
.navLinks {
	flex: 1;
}
.navLinks ul {
	width: 100%;
	background: var(--a-blue800);
	list-style-type: none;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.navLinks li {
	font-size: 18px;
	font-weight: 500;
	margin-left: 32px;
	cursor: pointer;
}

.navLinks button {
	all: unset;
	color: white;
}
.navLinks button.btn-outline {
	all: unset;
	color: white;
	background-color: transparent;
	padding: 12px 16px;
	border-radius: 4px;
	border: 2px solid var(--a-blue);
}
.navLinks button {
	background: var(--a-blue);
	padding: 12px 16px;
	border-radius: 4px;
}
@media screen and (max-width: 992px) {
	nav {
		padding: 20px 80px;
	}
}
@media screen and (max-width: 868px) {
	nav {
		width: 100%;
	}
	#logo {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
	}
	.menu {
		color: var(--c-light);
		font-size: 32px;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.navLinks {
		position: absolute;
		top: 10vh;
		left: 0;
		z-index: -99 !important;
		transition: all 0.5s ease;
		clip-path: circle(100px at 85% -50%);
		/* transform: translateY(-100%); */
		width: 100%;
		opacity: 1;
	}
	.navLinks ul {
		flex-direction: column;
		align-items: center;
	}
	.navLinks li {
		opacity: 0;
		transform: scale(0);
		margin: 24px 0px;
		transition: all 0.2s ease;
	}
	.active {
		clip-path: circle(1000px at 90% -10%);
		opacity: 1;
	}
	.navLinks.active ul li:nth-child(1) {
		opacity: 1;
		transition: all 0.5s ease 0.1s;
		transform: scale(1);
	}
	.navLinks.active ul li:nth-child(2) {
		transform: scale(1);
		opacity: 1;
		transition: all 0.5s ease 0.3s;
	}
	.navLinks.active ul li:nth-child(3) {
		transform: scale(1);
		opacity: 1;
		transition: all 0.5s ease 0.5s;
	}
	.navLinks.active ul li:nth-child(4) {
		transform: scale(1);
		opacity: 1;
		transition: all 0.5s ease 0.7s;
	}
	.navLinks.active ul li:nth-child(5) {
		transform: scale(1);
		opacity: 1;
		transition: all 0.5s ease 0.9s;
	}
	.navLinks.active ul li:nth-child(6) {
		transform: scale(1);
		opacity: 1;
		transition: all 0.5s ease 1.1s;
	}
}
@media screen and (max-width: 576px) {
	nav {
		padding: 20px 30px;
	}
}
