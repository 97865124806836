.blog {
  margin-top: 10vh;
  padding: 100px 120px;
}
.blog-list {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blog span.typed {
  font-size: 52px;
}
@media screen and (max-width: 1100px) {
  .blog {
    padding: 100px 90px;
  }
}
@media screen and (max-width: 992px) {
  .blog {
    padding: 100px 80px;
  }
  .blog span.typed {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .blog {
    padding: 100px 60px;
  }
  .blog span.typed {
    font-size: 36px;
  }
}
@media screen and (max-width: 578px) {
  .blog {
    padding: 100px 30px;
  }
}
