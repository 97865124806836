.newsletter {
  background: var(--a-blue800);
  padding: 100px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}
.newsletter h2 {
  color: var(--c-light);
  margin-bottom: 50px;
}
.newsletter .input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsletter .input-group input {
  all: unset;
  background: var(--c-light);
  width: 300px;
  padding: 20px 80px 20px 20px;
  border-radius: 4px;
  font-family: var(--ffamily-workSans);
  margin-right: 10px;
}

.newsletter .input-group .btn {
  color: var(--c-light);
  cursor: pointer;
  background: var(--a-blue);
  font-family: var(--ffamily-workSans);
  padding: 20px 50px;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .newsletter h2 {
    font-size: 24px;
  }
  .newsletter .input-group {
    flex-direction: column;
  }
  .newsletter .input-group input {
    padding: 20px 80px 20px 20px;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .newsletter .input-group .btn {
    text-align: center;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .newsletter {
    padding: 50px 30px;
  }
  .newsletter h2 {
    font-size: 24px;
  }
  .input-group {
    width: 100%;
  }
  .newsletter .input-group {
    flex-direction: column;
  }
  .newsletter .input-group input {
    box-sizing: border-box;
    padding: 20px 20px;
    margin-bottom: 10px;
    margin-right: 0px;
    width: 100%;
  }

  .newsletter .input-group .btn {
    text-align: center;
    width: 100%;
  }
}
