.alert {
  position: fixed;
  top: 12vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  font-family: var(--ffamily-workSans);
}
.alert.alert-success {
  background-color: #4caf50;
  color: #fff;
}
.alert.alert-danger {
  background-color: #f44336;
  color: #fff;
}
