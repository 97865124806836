.rowCards {
	background: var(--c-light);
	padding: 80px 120px;
}
.rowCards .card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 100px;
}
.rowCards .card .description {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 45%;
}
.rowCards .card .description p {
	text-align: justify;
}
.rowCards .card .row-image {
	perspective: 150rem;
	height: 250px;
	position: relative;
	width: 45%;
}
.rowCards .card .row-image img {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.row-image .card-side {
	transition: all 0.5s ease;
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}
.card-side.back {
	transform: rotateY(-180deg);
}

.row-image:hover .card-side.front {
	transform: rotateY(180deg);
}

.row-image:hover .card-side.back {
	transform: rotateY(0deg);
}
.rowCards .card .row-image img {
	width: 100%;
}

.card-reverse {
	flex-direction: row-reverse;
}
@media screen and (max-width: 1920px) {
	.rowCards .card .row-image {
		height: 385px;
	}
}
@media screen and (max-width: 1400px) {
	.rowCards {
		padding: 80px 160px;
	}
	.rowCards .card .row-image {
		height: 250px;
	}
}

@media screen and (max-width: 1200px) {
	.rowCards {
		padding: 80px 100px;
	}
	.title h3 {
		font-size: 32px;
	}
	.description p {
		font-size: 18px;
	}
	.rowCards .card .row-image {
		height: 250px;
	}
}
@media screen and (max-width: 992px) {
	.rowCards {
		padding: 80px 80px;
	}
	.title h3 {
		font-size: 28px;
	}
	.description p {
		font-size: 16px;
	}
}
@media screen and (max-width: 768px) {
	.rowCards .card {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 50px;
	}
	.rowCards {
		padding: 50px 60px;
	}
	.rowCards .card .description {
		width: 100%;
	}
	.rowCards .card .row-image {
		width: 80%;
	}
	.rowCards .card .row-image {
		height: 280px;
	}
}
@media screen and (max-width: 576px) {
	.title h3 {
		font-size: 24px !important;
	}
	.description p {
		font-size: 16px !important;
	}
	.rowCards {
		padding: 50px 30px;
	}
	.rowCards .card .row-image {
		height: 250px;
		width: 100%;
	}
}
