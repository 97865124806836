.faqContainer {
	padding: 100px 120px;
	display: flex;
	flex-direction: column;
}
.faqContainer h2 {
	text-align: center;
	margin-bottom: 90px;
}
.disclosure-btn {
	padding: 20px 50px;
	border-radius: 4px;
	margin: 20px 0px;
	background: var(--c-light);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	cursor: pointer;
}
.disclosure-btn .btn-top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.disclosure-btn .btn-top p {
	font-weight: 600;
}
.down-icon {
	font-size: 42px;
	color: var(--a-blue);
	transition: all 0.3s ease;
}
.toggle {
	transform: rotateZ(180deg);
}
.disclosure-panel {
	padding: 20px 0px;
}
.disclosure-panel p {
	white-space: pre-line;
	vertical-align: bottom;
}
@media screen and (max-width: 1200px) {
	.faqContainer {
		padding: 100px 100px;
	}
}
@media screen and (max-width: 992px) {
	.faqContainer {
		padding: 100px 80px;
	}
}
@media screen and (max-width: 768px) {
	.faqContainer {
		padding: 100px 60px;
	}
	.faqContainer h2 {
		font-size: 32px;
		margin-bottom: 45px;
	}
	.disclosure-btn {
		padding: 20px 40px;
	}
}
@media screen and (max-width: 576px) {
	.faqContainer {
		padding: 100px 30px;
	}
	.faqContainer h2 {
		font-size: 24px;
		margin-bottom: 45px;
	}
	.disclosure-btn {
		padding: 20px 15px;
	}
	.faqContainer p {
		font-size: 16px;
	}
}
