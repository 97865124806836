:root {
	--ffamily-prompt: "Prompt", sans-serif;
	--ffamily-workSans: "Work Sans", sans-serif;
	--c-light: #f6f6f6;
	--c-dark: #0d0d0d;
	--c-darkGrey: #161616;
	--c-ligtGrey: #e9e9e9;
	--a-blue: #1194bd;
	--a-blue800: #001116;
	--d-success: #4caf50;
	--d-danger: #f44336;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	background: var(--c-light);
}
h1 {
	font-family: var(--ffamily-prompt);
	font-weight: bold;
	font-size: 64px;
	color: var(--c-dark);
}
h2 {
	font-family: var(--ffamily-prompt);
	font-weight: bold;
	font-size: 42px;
	color: var(--c-dark);
}
h3 {
	font-family: var(--ffamily-prompt);
	font-weight: bold;
	font-size: 34px;
	color: var(--c-dark);
}
h4 {
	font-family: var(--ffamily-prompt);
	font-weight: 500;
	font-size: 22px;
	color: var(--c-dark);
}
p {
	font-family: var(--ffamily-workSans);
	font-weight: 500;
	font-size: 22px;
	color: var(--c-darkGrey);
	line-height: 151%;
}
a {
	transition: all 0.2s ease;
}
a:hover {
	filter: brightness(110%) !important;
}

.subtitle {
	font-family: var(--ffamily-workSans);
	font-weight: 500;
	font-size: 32px;
}
.pin-spacer {
	pointer-events: none;
}
