.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.spin {
  font-size: 80px;
  animation: load3 1.4s infinite linear;
  color: var(--a-blue);
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
