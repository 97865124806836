.form-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 120px;
  overflow-x: hidden;
  background: #fff;
}
.form-container h2 {
  font-size: 32px;
  font-family: var(--ffamily-workSans);
  margin-bottom: 16px;
}
.form-container .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  border: solid 1px rgba(54, 54, 54, 0.11);
  border-radius: 8px;
}
@media (max-width: 768px) {
  .form-container .form {
    padding: 30px 20px;
  }
}
.form-container .form .form-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container .form .form-details .create-tag {
  font-size: 12px;

  margin-bottom: 16px;
}
.form-container .form .form-details .input-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  font-family: var(--ffamily-workSans);
  gap: 8px;
  margin: 12px 0px;
}
.form-container .form .form-details .input-group label {
  font-size: 12px;
  font-family: var(--ffamily-workSans);
  color: rgb(138, 138, 138);
}
.form-container .form .form-details input {
  width: 300px;
  padding: 12px 8px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--ffamily-workSans);
}
.form-container .form .form-details .btn {
  width: 100%;
  text-align: center;
  outline: none;
  border: solid 1px rgba(59, 160, 255, 0.11);
  background: var(--a-blue);
  padding: 12px 16px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--ffamily-workSans);
  margin: 16px 0px;
  transition: all 0.2s ease;
}
.form-container .form .form-details .btn:hover {
  filter: brightness(120%);
}

.form-container .form .form-details span.new {
  width: 100%;
  color: var(--a-blue);
  font-size: 12px;
  font-family: var(--ffamily-workSans);
}
.form-container .form .form-details span.new:hover {
  text-decoration: underline;
}

.form-container .back-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--ffamily-workSans);
  color: var(--a-blue) !important;
  margin-top: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}
