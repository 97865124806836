.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 100px 120px;
  margin: auto;
}
.notfound img {
  width: 100%;
}
.notfound .info {
  margin-top: 20px;
}
@media screen and (max-width: 576px) {
  .notfound {
    padding: 100px 50px;
  }
  .notfound h2 {
    font-size: 24px;
  }
}
