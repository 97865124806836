.video-holder {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 110vh 120px 20px 120px;
}
.video-holder .video-container {
	width: 70%;
}
.video-holder video {
	width: 100%;
	border-radius: 8px;
}
@media screen and (max-width: 768px) {
	.video-holder {
		padding: 110vh 60px 100px 60px;
	}
	.video-holder .video-container {
		width: 100%;
	}
}
@media screen and (max-width: 576px) {
	.video-holder {
		padding: 110vh 50px 0px 50px;
	}
	.video-holder .video-container {
		width: 100%;
	}
}
