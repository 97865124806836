.gallery {
	margin-top: 5vh;
	padding: 100px 120px;
	width: 100%;
}
.gallery .searchCard {
	width: 100%;
	margin: 12px 0px;
	padding: 20px 15px;
	outline: none;
	border: 1px solid rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	font-size: 18px;
	font-family: var(--ffamily-workSans);
}
.gallery .downloadAllCards {
	width: 100%;
	margin: 16px 0px;
	display: flex;
}
.gallery .downloadAllCards .downloadAllBtn {
	width: 100%;
	padding: 20px 15px;
	outline: none;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	font-size: 18px;
	font-family: var(--ffamily-workSans);
	background-color: var(--a-blue800);
}
.categories {
	margin: 16px 0px;
}
.categories .category-list {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.categories .category-list button {
	margin-right: 10px;
	margin-top: 10px;
	padding: 8px 16px;
	outline: none;
	border: none;
	background-color: var(--a-blue);
	color: var(--c-light);
	font-weight: 600;
	font-family: var(--ffamily-workSans);
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;
}

.card-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0px -10px;
}

.flashcard {
	display: flex;
	flex-direction: column;
	width: 48%;
	padding: 30px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	margin: 10px;
}

.flashcard .btns .flip {
	margin: 20px 20px 0px 0px;
	padding: 10px 30px;
	outline: none;
	border: none;
	background-color: var(--a-blue);
	color: var(--c-light);
	font-weight: 500;
	font-family: var(--ffamily-workSans);
	font-size: 18px;
	border-radius: 4px;
	cursor: pointer;
}
.flashcard .btns .download {
	margin: 20px 20px 0px 0px;
	padding: 10px 30px;
	outline: none;
	border: none;
	background-color: var(--a-blue800);
	color: var(--c-light);
	font-weight: 500;
	font-family: var(--ffamily-workSans);
	font-size: 18px;
	border-radius: 4px;
	cursor: pointer;
}
.flashcard-image {
	width: 100%;
}
.flashcard-image img {
	width: 100%;
}

@media screen and (max-width: 1350px) {
	.flashcard {
		width: 100%;
	}
}
@media screen and (max-width: 992px) {
	.gallery {
		padding: 100px 80px;
	}
}
@media screen and (max-width: 768px) {
	.gallery {
		padding: 100px 60px;
	}
}

@media screen and (max-width: 576px) {
	.gallery {
		padding: 100px 50px;
	}
	.gallery .flashcard p {
		font-size: 18px;
		margin-top: 8px;
	}
	.categories .category-list button {
		padding: 10px 20px;
		font-size: 16px;
	}
	.gallery .flashcard .btns .download {
		padding: 10px 20px;
		font-size: 16px;
	}
	.gallery .flashcard .btns .flip {
		padding: 10px 20px;
		font-size: 16px;
	}
}
@media screen and (max-width: 450px) {
	.gallery {
		padding: 100px 30px;
	}
	.flashcard {
		padding: 15px;
	}
}
@media screen and (max-width: 360px) {
	.gallery {
		padding: 100px 30px;
	}
}
@media screen and (max-width: 330px) {
	.gallery {
		padding: 100px 10px;
	}
	.flashcard {
		padding: 10px;
	}
}
