.info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 100px 120px;
}
.info .info-header {
	text-align: center;
	width: 80%;
	margin-bottom: 30px;
}
.info .info-header p {
	margin: 8px 0px;
	color: var(--c-darkGrey);
}
.info .info-details {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
}
.info .info-details .detail {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0px 30px;
}
.info .info-details .detail img {
	width: 250px;
	margin-bottom: 8px;
}
.info .info-details .detail p {
	margin-top: 12px;
	font-size: 18px;
	text-align: center;
	opacity: 0.9;
}
@media screen and (max-width: 992px) {
	.info .info-header h3 {
		font-size: 28px;
	}
	.info .info-header p {
		font-size: 16px;
	}
	.info .info-details .detail p {
		font-size: 16px;
		text-align: justify;
	}
	.info .info-details .detail img {
		width: 200px;
		margin-bottom: 8px;
	}
}
@media screen and (max-width: 768px) {
	.info {
		padding: 50px 80px;
	}
	.info .info-header {
		width: 100%;
	}
	.info .info-header p {
		margin-top: 8px;
		text-align: justify;
	}
	.info .info-details {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
	}
	.info .info-details .detail {
		margin: 20px 0px;
	}
}
@media screen and (max-width: 576px) {
	.info {
		padding: 0px 30px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
