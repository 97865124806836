.hero {
  height: 100vh;
  padding: 0px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-image: url("./bg-updated.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background: var(--a-blue800); */
}
.hero .cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  z-index: 990;
  pointer-events: none;
}
.hero .cover .holder {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.hero .cover .holder img {
  width: 100px;
  margin-right: 8px;
}
.hero .cover .holder h1 {
  overflow: hidden;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
}
.hero .socials {
  position: absolute;
  top: 90%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 991;
  pointer-events: all;
}
.hero .socials a {
  font-family: var(--ffamily-workSans);
  margin-top: 4px;
}
.hero .socials a:hover {
  transform: scale(1.01);
}
.hero .hero-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
}
.hero .hero-details .heroImg {
  width: 80%;
  margin-bottom: 24px;
}
.hero .hero-details .heroImg img {
  width: 100%;
  border-radius: 8px;
}
.hero .hero-details h1 {
  color: var(--c-light);
}
.hero .hero-details h2 {
  color: var(--c-ligtGrey);
  margin-bottom: 20px;
  font-weight: 500;
}
.hero .hero-details .btn {
  background: var(--a-blue);
  font-family: var(--ffamily-workSans);
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 500;
  width: max-content;
  pointer-events: all;
}
.landing {
  background: var(--a-blue800);
  padding: 0px 120px;
}

@keyframes scroll-inner {
  0% {
    margin-top: 15%;
  }
  50% {
    margin-top: 100%;
  }
  100% {
    margin-top: 15%;
  }
}

div.mouse-container {
  position: absolute;
  display: block;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 100px;
}
div.mouse {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 60px;
  border: solid 2px #fff;
  border-radius: 25px;
  -webkit-animation: scroll-mouse 1.5s;
  animation: scroll-mouse 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
span.scroll-down {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: scroll-inner 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  font-family: var(--ffamily-prompt) !important;
}
.scroll-down-text {
  font-family: var(--ffamily-prompt) !important;
}
@media screen and (max-width: 1400px) {
  .hero {
    padding: 0px 80px;
  }
  .hero h1 {
    font-size: 56px;
  }
  .landing {
    padding: 0px 80px;
  }
}
@media screen and (max-width: 1200px) {
  .hero {
    padding: 0px 50px;
  }
  .hero h1 {
    font-size: 48px;
  }
  .landing {
    padding: 0px 50px;
  }
}
@media screen and (max-width: 992px) {
  .hero h1 {
    font-size: 42px;
  }
  .hero .hero-details h2 {
    font-size: 32px;
  }
  .landing {
    padding: 0px 30px;
  }
}
@media screen and (max-width: 768px) {
  .hero h1 {
    font-size: 42px;
  }
  .hero .hero-details h2 {
    font-size: 24px;
  }
  .landing {
    padding: 0px 10px;
  }
  .hero .hero-details .btn {
    padding: 12px 18px;
    font-size: 18px;
  }
}
@media screen and (max-width: 576px) {
  .hero {
    padding: 0px 30px;
    background-color: var(--a-blue800);
    background-image: none;
  }
  .hero h1 {
    font-size: 36px;
  }
  .hero .hero-details h2 {
    font-size: 18px;
    margin: 16px 0px;
  }
  .hero .hero-details .btn {
    padding: 12px 16px;
    font-size: 16px;
  }
}
