.dashboard {
  padding: 20px 120px;
}
.dashboard .dashboard-card {
  margin: 0px 0px;
  display: flex;
  width: 100%;
  background-color: var(--c-ligtGrey);
  border-radius: 4px;
}
.dashboard .dashboard-card .perks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: 30px;
}
.dashboard .dashboard-card .skewCard {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard .dashboard-card .skewCard img {
  width: 100%;
}
.dashboard .dashboard-card .perks .each-perk {
  display: flex;
  align-items: center;
  margin: 16px 0px;
}
.dashboard .dashboard-card .perks .each-perk .check {
  color: var(--d-success);
  font-size: 24px;
  margin-right: 16px;
}
.dashboard .dashboard-card .cta {
  width: 40%;
  background-color: var(--a-blue800);
  border-radius: 0px 4px 4px 0px;
  color: var(--c-light);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard .dashboard-card .cta h2,
.dashboard .dashboard-card .cta p {
  color: var(--c-light);
  margin: 4px 0px;
}

.dashboard .dashboard-card .cta button {
  width: 100%;
  padding: 10px 30px;
  outline: none;
  border: none;
  background-color: var(--a-blue);
  color: var(--c-light);
  font-weight: 500;
  font-family: var(--ffamily-workSans);
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
}

@media screen and (max-width: 1100px) {
  .dashboard {
    padding: 100px 120px;
  }
}
@media screen and (max-width: 992px) {
  .dashboard {
    padding: 100px 90px;
  }
  .dashboard .dashboard-card .cta h2 {
    font-size: 28px;
  }
  .carousel-container {
    padding: 0px 90px;
  }
}
@media screen and (max-width: 768px) {
  .dashboard {
    padding: 100px 80px;
  }
  .carousel-container {
    padding: 0px 80px;
  }
  .dashboard .dashboard-card .skewCard {
    width: 100%;
  }
  .dashboard .dashboard-card {
    flex-direction: column;
  }
  .dashboard .dashboard-card .perks .each-perk p {
    font-size: 18px;
  }
  .dashboard .dashboard-card .cta {
    padding: 50px 30px;
    width: 100%;
    border-radius: 0px 0px 4px 4px;
  }
  .dashboard .dashboard-card .cta p {
    margin: 12px 0px;
  }
  .dashboard .dashboard-card .perks {
    width: 100%;
  }
}
@media screen and (max-width: 578px) {
  .dashboard {
    padding: 100px 30px;
  }
  .carousel-container {
    padding: 0px 30px;
  }
}
