.footer {
	background: var(--c-light);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.footer h2 {
	padding: 80px 0px 10px 0px;
}
.footer .links {
	font-family: var(--ffamily-workSans);
	padding: 24px 0px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer .links ul {
	display: flex;
	flex-direction: column;
	list-style: none;
	width: 60%;
	justify-content: center;
	align-items: center;
}

.footer .links li {
	padding: 8px 0px;
}
.footer .links li a {
	color: var(--c-darkGrey);
	font-size: 18px;
	font-weight: 500;
}
.footer .links button {
	all: unset;
}
.footer .links button a {
	background: var(--a-blue);
	padding: 12px 16px;
	border-radius: 4px;
	color: var(--c-light) !important;
}

.footer .icons {
	margin-top: 12px;
	display: flex;
}
.footer .icons .icon-holder {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 20px;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	background: var(--a-blue);
	color: var(--c-light);
	font-size: 24px;
}
.footer .icons .icon-holder span {
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer .divider {
	height: 1px;
	background: var(--c-dark);
	width: 50%;
	margin: 30px 0px;
}
.footer .auth-details {
	display: flex;
	justify-content: center;
}
.footer .auth-details p {
	font-size: 16px;
	opacity: 0.8;
}
.footer .copyright {
	margin: 10px 0px 30px 0px;
}
@media screen and (max-width: 768px) {
	.footer h2 {
		font-size: 32px;
	}
	.footer .links ul {
		flex-direction: column;
		align-items: center;
	}
	.footer .links li {
		color: var(--c-darkGrey);
		font-size: 18px;
		font-weight: 500;
		padding: 30px 0px;
	}
}
@media screen and (max-width: 576px) {
	.footer h2 {
		font-size: 24px;
	}
	.footer .icons .icon-holder {
		height: 45px;
		width: 45px;
		font-size: 18px;
	}
}
