.buynow {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.buynow .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  border: solid 1px rgba(54, 54, 54, 0.11);
  border-radius: 8px;
  background: rgb(247, 247, 247);
}
.buynow .form .input-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  font-family: var(--ffamily-workSans);
  gap: 8px;
  margin: 12px 0px;
}
.buynow .form .input-group label {
  font-size: 12px;
  font-family: var(--ffamily-workSans);
  color: rgb(138, 138, 138);
}
.buynow .form .input-group input {
  width: 300px;
  padding: 12px 8px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--ffamily-workSans);
}

.buynow .form .country {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.buynow .form .country .country-btn {
  width: 300px;
  padding: 12px 8px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--ffamily-workSans);
}
.buynow .form .country .country-btn img {
  width: 36px;
  margin-right: 4px;
}
.buynow .form .country .country-btn span {
  margin: 0px 4px;
}
.buynow .form .country .country-list {
  background-color: white;
  width: 300px;
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0px 15px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.11);
}

.buynow .form .country .country-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.buynow .form .country .country-list::-webkit-scrollbar-track {
  background-color: rgb(187, 187, 187);
}

/* Handle */
.buynow .form .country .country-list::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.buynow .form .country ul .country-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.11);
  font-family: var(--ffamily-workSans);
  padding: 8px 0px;
  cursor: pointer;
}
.buynow .form .country .country-list .country-item img {
  width: 36px;
  margin-right: 4px;
}
.buynow .form .country .country-list .country-item span {
  margin: 0px 4px;
}
.buynow .form .country .dropdown-menu {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}
.buynow .form .country .country {
  color: black;
  display: flex;
  flex-direction: column;
}
.buynow .form button {
  width: 100%;
  text-align: center;
  outline: none;
  border: solid 1px rgba(59, 160, 255, 0.11);
  background: var(--a-blue);
  padding: 12px 16px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--ffamily-workSans);
  margin: 16px 0px;
  transition: all 0.2s ease;
}
.buynow .form button:hover {
  filter: brightness(120%);
}
.form p.error {
  color: rgb(255, 54, 54) !important;
}
.spin {
  animation: load3 1.4s infinite linear;
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.back-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--ffamily-workSans);
  color: var(--a-blue) !important;
  margin-top: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}
